import React from 'react';
import { ReportsReputacion } from '../reports-reputacion';
import './dashboard-admin.css';
import { ReportsStockBreaksFines } from '../reports-stockbreaksfines';
import { ReportsHome } from '../reports-home';

export const DashboardAdmin = () => {
  return (
    <>
      <ReportsHome />
      <ReportsReputacion />
      <ReportsStockBreaksFines />
    </>
  );
};
