import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@cencosud-ds/bigbang';

const Dialog = ({ open, onClose, children }) => {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: 16,
        },
      }}
    >
      <div
        style={{
          margin: 0,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <Icon name="Close" />
          </IconButton>
        ) : null}
      </div>
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
};

export default Dialog;
