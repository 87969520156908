import { getMicrofrontends, getRemoteMicrofrontends } from './utils/microfrontends';
import { ImportMap } from './interfaces/importMap.interface';
import { Manifest } from './interfaces/manifest.interface';
import { Permissions } from './interfaces/permission.interface';
import { setManifest } from './utils/manifestFunctions';
// @ts-ignore
import Yml from 'yamljs';
import { SPA_ROUTES_READY } from '../config';
import { RouteInterface } from './interfaces/route.interface';
import { MicrofrontendInterface } from './interfaces/microfrontend.interface';
import objectHasProperty from '../utils/objectHasProperty';
import { getServicesStatus } from './getServicesStatus';

export default async function loadDependencies() {
  const microfrontends = getMicrofrontends();
  const routesForMenu: RouteInterface[] = [];
  const routes: any = {};
  const permissions: any = {};

  const promises: any[] = [getServicesStatus()];
  for (const microfrontend of microfrontends) {
    promises.push(handleDependencies(microfrontend, routesForMenu, permissions, routes));
  }

  const [servicesStatus] = await Promise.all(promises);

  const routeEvent = new Event(SPA_ROUTES_READY);

  // @ts-ignore
  routeEvent.routes = routesForMenu;
  // @ts-ignore
  routeEvent.permissions = permissions;
  // @ts-ignore
  routeEvent.servicesStatus = servicesStatus;

  window.dispatchEvent(routeEvent);

  return { permissions, routes, servicesStatus };
}

const getImports = (microfrontend: MicrofrontendInterface, manifest: any): ImportMap => {
  let moduleFederationRemotes: ModuleFederationData[] = [];
  try {
    moduleFederationRemotes = getRemoteMicrofrontends();
  } catch (error) {
    throw new Error('REACT_APP_MODULE_FEDERATED_REMOTES env var not setted');
  }
  const currentMicrofront = moduleFederationRemotes.find(m => m.name === microfrontend.name);
  return {
    imports: {
      [microfrontend.name]: currentMicrofront
        ? // MKP: se modifica archivo a cargar-> esto equivale a cargar el remoteEntry.js
          `${microfrontend.url}${manifest[currentMicrofront.manifestRemoteEntryPropertyName]}`
        : `${microfrontend.url}${manifest['main.js']}`,
      [`${microfrontend.name}-variables`]: `${microfrontend.url}/config.js`,
    },
  };
};

const handleDependencies = async (
  microfrontend: MicrofrontendInterface,
  routesForMenu: RouteInterface[],
  permissions: any,
  routes: any,
) => {
  try {
    const response = await fetch(`${microfrontend.url}/asset-manifest.json?timestamp=${new Date().getTime()}`, {
      cache: 'no-store',
    });
    const contentType = response.headers.get('content-type');
    if (!response.ok) {
      return console.error('Error al cargar el manifiesto', microfrontend.url, response.status, response.statusText);
    }

    if (contentType && contentType.includes('text/html')) {
      return console.error('Se recibió una respuesta HTML en lugar de JSON');
    }

    const manifest = await response.json();
    setManifest(microfrontend, manifest);
    const script = document.createElement('script');
    script.type = 'systemjs-importmap';
    script.innerHTML = JSON.stringify(getImports(microfrontend, manifest));
    document.getElementsByTagName('head')[0].appendChild(script);

    if (objectHasProperty(manifest, 'static/media/permissions.yml')) {
      const permissionsFromMicrofrontend = await getPermissions(microfrontend.url, manifest);
      permissions[microfrontend.name] = permissionsFromMicrofrontend;
    }

    if (objectHasProperty(manifest, 'static/media/routes.yml')) {
      const routesFromMicrofrontend = await getRoutes(microfrontend.url, manifest);
      routes[microfrontend.name] = routesFromMicrofrontend;
      for (const route of routesFromMicrofrontend) {
        routesForMenu.push(route);
      }
    }
  } catch (error) {
    console.log('Failed fetching', microfrontend);
    console.log(error);
  }
};

const getRoutes = async (url: string, manifest: Manifest) => {
  const manifestRoutes = [];

  const ymlRoutes = await fetch(`${url}${manifest['static/media/routes.yml']}?timestamp=${new Date().getTime()}`, {
    cache: 'no-store',
  });
  const jsonRoutes = Yml.parse(await ymlRoutes.clone().text());

  for (const key of Object.keys(jsonRoutes)) {
    const route: RouteInterface = {
      id: key,
      exact: true,
      ...jsonRoutes[key],
    };
    manifestRoutes.push(route);
  }

  return manifestRoutes;
};

const getPermissions = async (url: string, manifest: Manifest) => {
  const ymlPermissionRoutes = await fetch(
    `${url}${manifest['static/media/permissions.yml']}?timestamp=${new Date().getTime()}`,
    {
      cache: 'no-store',
    },
  );

  const permissions: Permissions = {
    sellerRoutes: [],
    externalSellerRoutes: [],
    catalogRoutes: [],
    comercialRoutes: [],
    logisticsRoutes: [],
    customerServiceRoutes: [],
    storeRoutes: [],
  };
  const permissionsFromMicrofrontend = Yml.parse(await ymlPermissionRoutes.clone().text());

  return {
    ...permissions,
    ...permissionsFromMicrofrontend,
  };
};

export interface ModuleFederationData {
  name: string;
  manifestRemoteEntryPropertyName: string;
  appModule: string;
}
