import React, { useEffect, useContext, useState } from 'react';
import { Loader, Typography } from '@cencosud-ds/bigbang';
import { ADMIN, SELLER } from 'variables/roles';
import SessionContext from 'api/session/context';
import { getDashboard } from 'api/report/index';
import t from '../../../../utils/translate';

import './style.scss';

export function ReportsStockBreaksFines() {
  const [iframeUrl, setIframeUrl] = useState('');
  const [showLoading, setShowLoading] = useState(true);

  const {
    auth: { jwt, role },
  } = useContext(SessionContext);

  const loadToFalse = () => {
    setShowLoading(false);
  };

  const fetchReportReputation = async () => {
    try {
      const url = await getDashboard(jwt, 'penaltyBreak');
      setIframeUrl(url);
    } catch (error) {
      console.log('Ocurrio un error al cargar reporte en reputation', error);
    }
  };

  useEffect(() => {
    if ([SELLER, ADMIN].includes(role)) {
      fetchReportReputation();
    }
  }, []);

  return (
    <div className="container-report">
      <Typography color={`#4C5773`} propsElement={{}} typeElement="subtitle1" weight="bold">
        {t('reports_penaltybreak_title')}
      </Typography>
      <>
        {showLoading && <Loader />}
        <iframe
          id="ReportsPenaltyBreak"
          onLoad={loadToFalse}
          title="Report 360"
          frameBorder="0"
          height="505px"
          width="100%"
          src={iframeUrl}
        />
      </>
    </div>
  );
}
