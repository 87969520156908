import { uniq } from 'lodash';
import objectHasProperty from '../../utils/objectHasProperty';

export const orderByParentsAndChildrens = routes => {
  const childrens = routes.filter(r => objectHasProperty(r, 'parentId') && r.parentId);
  const parentIds = uniq(childrens.map(r => r.parentId));

  routes = routes.map(route => {
    if (parentIds.includes(route.id)) {
      route.subRoutes = childrens.filter(r => r.parentId === route.id);
    }
    return route;
  });

  const menuPriorities = {
    Config: {
      Sellers: 1,
      Rules: 2,
      Users: 3,
      PimConfiguration: 4,
      CSReturns: 5,
      default: 999,
    },
    Sales: {
      Returns: 1,
      InvoiceHome: 2,
      OrdersV2: 3,
      Logistics: 4,
      Fulfillment: 4,
      default: 999,
    },
  };

  for (const route of routes) {
    if (Array.isArray(route.subRoutes)) {
      route.subRoutes = route.subRoutes.sort((route1, route2) => {
        let priorityFromRoute1 = 999,
          priorityFromRoute2 = 999;

        if (objectHasProperty(menuPriorities, route1.parentId)) {
          priorityFromRoute1 = menuPriorities[route1.parentId].default;
          if (objectHasProperty(menuPriorities[route1.parentId], route1.id)) {
            priorityFromRoute1 = menuPriorities[route1.parentId][route1.id];
          }
        }

        if (objectHasProperty(menuPriorities, route2.parentId)) {
          priorityFromRoute2 = menuPriorities[route2.parentId].default;
          if (objectHasProperty(menuPriorities[route2.parentId], route2.id)) {
            priorityFromRoute2 = menuPriorities[route2.parentId][route2.id];
          }
        }

        if (priorityFromRoute1 > priorityFromRoute2) {
          return 1;
        }

        if (priorityFromRoute1 < priorityFromRoute2) {
          return -1;
        }

        return 0;
      });
    }
  }

  return routes;
};
