import React from 'react';
import Reputacion from './reputation';
import Metrics from './metrics';
import { ReportsHome } from '../reports-home';
import { ReportsReputacion } from '../reports-reputacion';
import { ReportsStockBreaksFines } from '../reports-stockbreaksfines';

export function DashboardSellers() {
  return (
    <>
      <Reputacion />
      <Metrics />
      <ReportsHome />
      <ReportsReputacion />
      <ReportsStockBreaksFines />
    </>
  );
}
